import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The AWSDate scalar type represents a valid extended ISO 8601 Date string. In other words, this scalar type accepts date strings of the form YYYY-MM-DD. This scalar type can also accept time zone offsets. For example, 1970-01-01Z, 1970-01-01-07:00 and 1970-01-01+05:30 are all valid dates. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: { input: string; output: string };
  /** The AWSDateTime scalar type represents a valid extended ISO 8601 DateTime string. In other words, this scalar type accepts datetime strings of the form YYYY-MM-DDThh:mm:ss.sssZ. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). The time zone offset is compulsory for this scalar. The time zone offset must either be Z (representing the UTC time zone) or be in the format ±hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: { input: string; output: string };
  /** The AWSEmail scalar type represents an Email address string that complies with RFC 822. For example, username@example.com is a valid Email address. */
  AWSEmail: { input: string; output: string };
  /** The AWSIPAddress scalar type represents a valid IPv4 or IPv6 address string. */
  AWSIPAddress: { input: string; output: string };
  /**
   * The AWSJSON scalar type represents a JSON string that complies with RFC 8259.
   *
   * Maps like {\"upvotes\": 10}, lists like [1,2,3], and scalar values like \"AWSJSON example string\", 1, and true are accepted as valid JSON. They will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings. Invalid JSON strings like {a: 1}, {'a': 1} and Unquoted string will throw GraphQL validation errors.
   */
  AWSJSON: { input: string; output: string };
  /** The AWSPhone scalar type represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Phone numbers provided may be whitespace delimited or hyphenated. The number can specify a country code at the beginning but this is not required. */
  AWSPhone: { input: string; output: string };
  /**
   * The AWSTime scalar type represents a valid extended ISO 8601 Time string. In other words, this scalar type accepts time strings of the form hh:mm:ss.sss. The field after the seconds field is a nanoseconds field. It can accept between 1 and 9 digits. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used). This scalar type can also accept time zone offsets.
   *
   * For example, 12:30Z, 12:30:24-07:00 and 12:30:24.500+05:30 are all valid time strings.
   *
   * The time zone offset must either be Z (representing the UTC time zone) or be in the format hh:mm:ss. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard.
   */
  AWSTime: { input: string; output: string };
  /** The AWSTimestamp scalar type represents the number of seconds that have elapsed since 1970-01-01T00:00Z. Timestamps are serialized and deserialized as numbers. Negative values are also accepted and these represent the number of seconds till 1970-01-01T00:00Z. */
  AWSTimestamp: { input: string; output: string };
  /** The AWSURL scalar type represents a valid URL string. The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>). URLs without schemes are considered invalid. URLs which contain double slashes are also considered invalid. */
  AWSURL: { input: string; output: string };
};

export type AddBusinessDaysInput = {
  days?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  suite?: Maybe<Scalars['String']['output']>;
};

export type Autopay = {
  __typename?: 'Autopay';
  collectionFiles?: Maybe<Array<PaymentFile>>;
  collectionPosted?: Maybe<Scalars['AWSDate']['output']>;
  completedZ?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  listFees?: Maybe<AutopayFeeConnection>;
  listSettlementSummaries?: Maybe<SettlementSummaryConnection>;
  nsfCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<SettlementSummary>;
  /** Autopay V1 fields */
  taskToken?: Maybe<Scalars['String']['output']>;
  /** Autopay V2 fields */
  version?: Maybe<Scalars['String']['output']>;
};

export type AutopayListFeesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AutopayListSettlementSummariesArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type AutopaySummaryArgs = {
  id: Scalars['ID']['input'];
};

export type AutopayConnection = {
  __typename?: 'AutopayConnection';
  items?: Maybe<Array<Autopay>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type AutopayFee = {
  __typename?: 'AutopayFee';
  accountId: Scalars['ID']['output'];
  accountName?: Maybe<Scalars['String']['output']>;
  autopayId: Scalars['ID']['output'];
  feeAmount?: Maybe<Scalars['Float']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  paymentCount?: Maybe<Scalars['Int']['output']>;
  transactionFee?: Maybe<Scalars['Float']['output']>;
};

export type AutopayFeeConnection = {
  __typename?: 'AutopayFeeConnection';
  items?: Maybe<Array<AutopayFee>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type AutopayPayload = {
  __typename?: 'AutopayPayload';
  autopay?: Maybe<Autopay>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AutopaySummaryInput = {
  autopayId: Scalars['ID']['input'];
  settlementId: Scalars['ID']['input'];
};

export type Bank = {
  __typename?: 'Bank';
  accountNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  institutionNumber: Scalars['String']['output'];
  name: Scalars['String']['output'];
  transitNumber: Scalars['String']['output'];
};

export type BankInput = {
  accountNumber: Scalars['String']['input'];
  institutionNumber: Scalars['String']['input'];
  name: Scalars['String']['input'];
  transitNumber: Scalars['String']['input'];
};

export type BankPayload = {
  __typename?: 'BankPayload';
  bank?: Maybe<Bank>;
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type BillingAccount = {
  __typename?: 'BillingAccount';
  address?: Maybe<Address>;
  domainId: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BillingDetailRecord = {
  __typename?: 'BillingDetailRecord';
  amount: Scalars['Float']['output'];
  billingAccountId?: Maybe<Scalars['String']['output']>;
  cycle: InvoiceCycle | `${InvoiceCycle}`;
  dateKey: Scalars['String']['output'];
  description: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  domainId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceId: Scalars['String']['output'];
  product: Product | `${Product}`;
  quantity: Scalars['Float']['output'];
  rates: Array<RatedBand>;
  skuId: Scalars['String']['output'];
  skuName: Scalars['String']['output'];
  usageId: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type Charge = {
  __typename?: 'Charge';
  amount: Scalars['Float']['output'];
  chargedZ: Scalars['AWSDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  postalLabel: Scalars['String']['output'];
  provinceLabel: Scalars['String']['output'];
  provinces: Array<Province>;
  timezone: Scalars['String']['output'];
};

export type CreateAutopayInput = {
  clearablesPosted?: InputMaybe<Scalars['AWSDate']['input']>;
  collectionPosted: Scalars['AWSDate']['input'];
};

export type CreateSaaSFeesInput = {
  fees: Array<SaaSFeeInput>;
};

export enum EventSource {
  System = 'system',
}

export type Holiday = {
  __typename?: 'Holiday';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Float']['output'];
  billingAccount: BillingAccount;
  billingAccountId?: Maybe<Scalars['String']['output']>;
  billingBankId?: Maybe<Scalars['String']['output']>;
  billingName?: Maybe<Scalars['String']['output']>;
  charges?: Maybe<Array<Charge>>;
  cycle: InvoiceCycle | `${InvoiceCycle}`;
  domain: Scalars['String']['output'];
  domainId: Scalars['String']['output'];
  due?: Maybe<Scalars['AWSDate']['output']>;
  executionId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiced: Scalars['AWSDate']['output'];
  lines?: Maybe<Array<InvoiceLine>>;
  outstanding?: Maybe<Scalars['Float']['output']>;
  period: Scalars['String']['output'];
  startedZ: Scalars['AWSDateTime']['output'];
  status: Scalars['String']['output'];
  statusHistory: Array<StatusHistory>;
  taxes?: Maybe<Array<TaxLine>>;
};

export enum InvoiceCycle {
  Daily = 'daily',
  Monthly = 'monthly',
}

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  amount: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  driver: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  product: Product | `${Product}`;
  quantity: Scalars['Float']['output'];
  skuId: Scalars['String']['output'];
  skuName: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type ListAutopaySummariesFilter = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  continueAutopay: SuccessPayload;
  createAutopay: AutopayPayload;
  createSaaSFees: SuccessPayload;
  registerBank: BankPayload;
  /** Called to send an event to subscribers */
  systemEvent?: Maybe<SystemEvent>;
};

export type MutationContinueAutopayArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCreateAutopayArgs = {
  input: CreateAutopayInput;
};

export type MutationCreateSaaSFeesArgs = {
  input: CreateSaaSFeesInput;
};

export type MutationRegisterBankArgs = {
  input: RegisterBankInput;
};

export type MutationSystemEventArgs = {
  input: SystemEventInput;
};

export type OldVersionMatch = {
  __typename?: 'OldVersionMatch';
  errors?: Maybe<Array<Maybe<ScannerError>>>;
  schema?: Maybe<Scalars['String']['output']>;
};

export type PaymentFile = {
  __typename?: 'PaymentFile';
  fileEntries: Scalars['Int']['output'];
  fileNumber: Scalars['String']['output'];
  fileTotal: Scalars['Float']['output'];
  journalsTotal?: Maybe<Scalars['Float']['output']>;
};

export enum Product {
  AutopayCollectionsEft = 'autopay_collections_eft',
  AutopayCollectionsEftReturns = 'autopay_collections_eft_returns',
  AutopayPaymentsEft = 'autopay_payments_eft',
  AutopayPaymentsEftReturns = 'autopay_payments_eft_returns',
  AutopaySingleEft = 'autopay_single_eft',
  AutopaySingleEftReturns = 'autopay_single_eft_returns',
  Tax = 'tax',
  Unit = 'unit',
}

export type Province = {
  __typename?: 'Province';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  addBusinessDays?: Maybe<Scalars['AWSDate']['output']>;
  autopay?: Maybe<Autopay>;
  autopaySummary?: Maybe<SettlementSummary>;
  countries?: Maybe<Array<Country>>;
  holidays?: Maybe<Array<Holiday>>;
  invoice?: Maybe<Invoice>;
  listAutopaySummaries?: Maybe<SettlementSummaryConnection>;
  listAutopays?: Maybe<AutopayConnection>;
  pageUnits?: Maybe<UnitsPage>;
  scan?: Maybe<Scan>;
  scans?: Maybe<Array<Maybe<Scan>>>;
  toggle: TogglePayload;
  toggles?: Maybe<Array<Maybe<Toggle>>>;
};

export type QueryAddBusinessDaysArgs = {
  input?: InputMaybe<AddBusinessDaysInput>;
};

export type QueryAutopayArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAutopaySummaryArgs = {
  input: AutopaySummaryInput;
};

export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryListAutopaySummariesArgs = {
  filter?: InputMaybe<ListAutopaySummariesFilter>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPageUnitsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<UnitsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UnitsSortInput>>;
};

export type QueryScanArgs = {
  id: Scalars['ID']['input'];
};

export type QueryToggleArgs = {
  input: ToggleInput;
};

export type RatedBand = {
  __typename?: 'RatedBand';
  amount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  portion: Scalars['Float']['output'];
};

export type RegisterBankInput = {
  accountId: Scalars['ID']['input'];
  bank: BankInput;
  clientAccountId: Scalars['ID']['input'];
};

export type RelayEdge = {
  /** node: [<Type from implementation>!]! */
  cursor: Scalars['ID']['output'];
};

export type RelayPage = {
  edges: Array<RelayEdge>;
  pageInfo: RelayPageInfo;
};

export type RelayPageInfo = {
  __typename?: 'RelayPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type SaaSFeeInput = {
  accountId: Scalars['ID']['input'];
  autopayId: Scalars['ID']['input'];
  feeAmount: Scalars['Float']['input'];
};

export type Scan = {
  __typename?: 'Scan';
  errors?: Maybe<ScannerResultConnection>;
  id: Scalars['ID']['output'];
  recordsScanned?: Maybe<Scalars['Int']['output']>;
  scanCompleteZ?: Maybe<Scalars['AWSDateTime']['output']>;
  scanStartZ: Scalars['AWSDateTime']['output'];
  table?: Maybe<Scalars['String']['output']>;
  ttl?: Maybe<Scalars['Int']['output']>;
};

export type ScanErrorsArgs = {
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type ScannerError = {
  __typename?: 'ScannerError';
  instancePath?: Maybe<Scalars['String']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  params?: Maybe<Scalars['AWSJSON']['output']>;
  schemaPath?: Maybe<Scalars['String']['output']>;
};

export type ScannerResult = {
  __typename?: 'ScannerResult';
  errors?: Maybe<Array<Maybe<ScannerError>>>;
  id: Scalars['ID']['output'];
  matchedOldVersion?: Maybe<OldVersionMatch>;
  record?: Maybe<Scalars['AWSJSON']['output']>;
  scanStartZ: Scalars['AWSDateTime']['output'];
  schema?: Maybe<Scalars['String']['output']>;
  table?: Maybe<Scalars['String']['output']>;
  ttl?: Maybe<Scalars['Int']['output']>;
};

export type ScannerResultConnection = {
  __typename?: 'ScannerResultConnection';
  items?: Maybe<Array<Maybe<ScannerResult>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type SettlementSummary = {
  __typename?: 'SettlementSummary';
  accountId?: Maybe<Scalars['ID']['output']>;
  /** Autopay V1 fields */
  accountName?: Maybe<Scalars['String']['output']>;
  autopayId: Scalars['ID']['output'];
  /** Autopay V2 fields */
  bankId?: Maybe<Scalars['ID']['output']>;
  billingName?: Maybe<Scalars['String']['output']>;
  collectionPosted: Scalars['AWSDate']['output'];
  feePaymentTotal?: Maybe<Scalars['Float']['output']>;
  feesTotal?: Maybe<Scalars['Float']['output']>;
  fileNumber?: Maybe<Scalars['String']['output']>;
  gstAmount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  items?: Maybe<Scalars['Int']['output']>;
  journalsTotal?: Maybe<Scalars['Float']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  paymentCount?: Maybe<Scalars['Int']['output']>;
  returnedItems?: Maybe<Scalars['Int']['output']>;
  returnedTotal?: Maybe<Scalars['Float']['output']>;
  settledZ?: Maybe<Scalars['AWSDateTime']['output']>;
  status?: Maybe<SettlementSummaryStatus | `${SettlementSummaryStatus}`>;
  total?: Maybe<Scalars['Float']['output']>;
  transactionFeesAmount?: Maybe<Scalars['Float']['output']>;
};

export type SettlementSummaryConnection = {
  __typename?: 'SettlementSummaryConnection';
  items?: Maybe<Array<SettlementSummary>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export enum SettlementSummaryStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Presettled = 'PRESETTLED',
  Settled = 'SETTLED',
  Started = 'STARTED',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type StatusHistory = {
  __typename?: 'StatusHistory';
  status: Scalars['String']['output'];
  z: Scalars['AWSDateTime']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onSystemEvent?: Maybe<SystemEvent>;
};

export type SuccessPayload = {
  __typename?: 'SuccessPayload';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SystemEvent = {
  __typename?: 'SystemEvent';
  detail?: Maybe<Scalars['AWSJSON']['output']>;
  detailType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  source?: Maybe<EventSource | `${EventSource}`>;
  time?: Maybe<Scalars['String']['output']>;
};

export type SystemEventInput = {
  event: Scalars['AWSJSON']['input'];
};

export type TaxLine = {
  __typename?: 'TaxLine';
  amount: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  product: Product | `${Product}`;
  skuId: Scalars['String']['output'];
  skuName: Scalars['String']['output'];
};

export type Toggle = {
  __typename?: 'Toggle';
  accountId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type ToggleInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type TogglePayload = {
  __typename?: 'TogglePayload';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  toggle?: Maybe<Toggle>;
};

export type Unit = {
  __typename?: 'Unit';
  accountId: Scalars['ID']['output'];
  accountName: Scalars['String']['output'];
  additionalInformation?: Maybe<Scalars['String']['output']>;
  bathrooms: Scalars['Float']['output'];
  bedrooms: Scalars['Float']['output'];
  buildingId?: Maybe<Scalars['ID']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  legalAddress?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  occupancy?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  propertyId: Scalars['String']['output'];
  propertyKey: Scalars['String']['output'];
  propertyName: Scalars['String']['output'];
  sqft: Scalars['Float']['output'];
  test?: Maybe<Scalars['Boolean']['output']>;
};

export type UnitEdge = RelayEdge & {
  __typename?: 'UnitEdge';
  cursor: Scalars['ID']['output'];
  node: Unit;
};

export type UnitsFilterInput = {
  /** Only include Units with the account ID specified */
  accountId?: InputMaybe<Scalars['ID']['input']>;
  /** Only include Units with the owner ID specified */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** Only include Units with any of owner IDs specified */
  ownerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Only include Units with the property ID specified */
  propertyId?: InputMaybe<Scalars['ID']['input']>;
  /** Only include Units with any of property IDs specified */
  propertyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter results to include only Units matching all space-delimited terms in the search string */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UnitsPage = RelayPage & {
  __typename?: 'UnitsPage';
  edges: Array<UnitEdge>;
  pageInfo: RelayPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum UnitsSortField {
  Amount = 'amount',
  BuildingKey = 'buildingKey',
  BuildingName = 'buildingName',
  Cleared = 'cleared',
  Description = 'description',
  Due = 'due',
  Outstanding = 'outstanding',
  OwnerName = 'ownerName',
  PayeeName = 'payeeName',
  Posted = 'posted',
  PropertyKey = 'propertyKey',
  PropertyName = 'propertyName',
  Ref = 'ref',
  UnitName = 'unitName',
}

export type UnitsSortInput = {
  direction: SortDirection | `${SortDirection}`;
  field: UnitsSortField | `${UnitsSortField}`;
};

export type SystemEventsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SystemEventsSubscription = {
  __typename?: 'Subscription';
  onSystemEvent?: {
    __typename?: 'SystemEvent';
    source?: EventSource;
    detail?: string;
    detailType?: string;
    id?: string;
    time?: string;
  };
};

export type GetTogglesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTogglesQuery = {
  __typename?: 'Query';
  toggles?: Array<{
    __typename?: 'Toggle';
    id: string;
    accountId: string;
    description?: string;
    enabled: boolean;
  }>;
};

export type GetScanErrorsQueryVariables = Exact<{
  scanStartZ: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetScanErrorsQuery = {
  __typename?: 'Query';
  scan?: {
    __typename?: 'Scan';
    id: string;
    errors?: {
      __typename?: 'ScannerResultConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'ScannerResult';
        id: string;
        table?: string;
        scanStartZ: string;
        ttl?: number;
        record?: string;
        schema?: string;
        errors?: Array<{
          __typename?: 'ScannerError';
          instancePath?: string;
          message?: string;
          params?: string;
        }>;
        matchedOldVersion?: { __typename?: 'OldVersionMatch'; schema?: string };
      }>;
    };
  };
};

export type GetScansQueryVariables = Exact<{ [key: string]: never }>;

export type GetScansQuery = {
  __typename?: 'Query';
  scans?: Array<{
    __typename?: 'Scan';
    id: string;
    scanStartZ: string;
    recordsScanned?: number;
    scanCompleteZ?: string;
    table?: string;
    ttl?: number;
  }>;
};

export type CollectionPostedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CollectionPostedQuery = {
  __typename?: 'Query';
  autopay?: {
    __typename?: 'Autopay';
    id: string;
    status?: string;
    collectionFiles?: Array<{
      __typename?: 'PaymentFile';
      fileNumber: string;
      fileTotal: number;
      fileEntries: number;
      journalsTotal?: number;
    }>;
  };
};

export type CollectionReconciledQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type CollectionReconciledQuery = {
  __typename?: 'Query';
  autopay?: {
    __typename?: 'Autopay';
    id: string;
    status?: string;
    listFees?: {
      __typename?: 'AutopayFeeConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'AutopayFee';
        id: string;
        autopayId: string;
        accountId: string;
        accountName?: string;
        fileNumber?: string;
        paymentCount?: number;
        feeAmount?: number;
        transactionFee?: number;
      }>;
    };
  };
};

export type SettlementPreparedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type SettlementPreparedQuery = {
  __typename?: 'Query';
  autopay?: {
    __typename?: 'Autopay';
    id: string;
    status?: string;
    listSettlementSummaries?: {
      __typename?: 'SettlementSummaryConnection';
      nextToken?: string;
      items?: Array<{
        __typename?: 'SettlementSummary';
        id: string;
        accountId?: string;
        accountName?: string;
        fileNumber?: string;
        paymentAmount?: number;
        feesTotal?: number;
        feePaymentTotal?: number;
        journalsTotal?: number;
      }>;
    };
  };
};

export type AutopayQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AutopayQuery = {
  __typename?: 'Query';
  autopay?: {
    __typename?: 'Autopay';
    id: string;
    collectionPosted?: string;
    status?: string;
    taskToken?: string;
    nsfCount?: number;
  };
};

export type AutopaysQueryVariables = Exact<{ [key: string]: never }>;

export type AutopaysQuery = {
  __typename?: 'Query';
  listAutopays?: {
    __typename?: 'AutopayConnection';
    nextToken?: string;
    items?: Array<{
      __typename?: 'Autopay';
      id: string;
      collectionPosted?: string;
      status?: string;
      taskToken?: string;
      version?: string;
    }>;
  };
};

export type CreateAutopayMutationVariables = Exact<{
  input: CreateAutopayInput;
}>;

export type CreateAutopayMutation = {
  __typename?: 'Mutation';
  createAutopay: {
    __typename?: 'AutopayPayload';
    success?: boolean;
    error?: string;
    autopay?: {
      __typename?: 'Autopay';
      id: string;
      collectionPosted?: string;
      taskToken?: string;
      status?: string;
    };
  };
};

export type ContinueAutopayMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ContinueAutopayMutation = {
  __typename?: 'Mutation';
  continueAutopay: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export type CreateSaaSFeesMutationVariables = Exact<{
  input: CreateSaaSFeesInput;
}>;

export type CreateSaaSFeesMutation = {
  __typename?: 'Mutation';
  createSaaSFees: { __typename?: 'SuccessPayload'; success?: boolean; error?: string };
};

export const SystemEventsDocument = gql`
  subscription SystemEvents {
    onSystemEvent {
      source
      detail
      detailType
      id
      time
    }
  }
`;

/**
 * __useSystemEventsSubscription__
 *
 * To run a query within a React component, call `useSystemEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSystemEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemEventsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSystemEventsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SystemEventsSubscription,
    SystemEventsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<SystemEventsSubscription, SystemEventsSubscriptionVariables>(
    SystemEventsDocument,
    options
  );
}
export type SystemEventsSubscriptionHookResult = ReturnType<typeof useSystemEventsSubscription>;
export type SystemEventsSubscriptionResult = Apollo.SubscriptionResult<SystemEventsSubscription>;
export const GetTogglesDocument = gql`
  query GetToggles {
    toggles {
      id
      accountId
      description
      enabled
    }
  }
`;

/**
 * __useGetTogglesQuery__
 *
 * To run a query within a React component, call `useGetTogglesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTogglesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTogglesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTogglesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTogglesQuery, GetTogglesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTogglesQuery, GetTogglesQueryVariables>(GetTogglesDocument, options);
}
export function useGetTogglesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTogglesQuery, GetTogglesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTogglesQuery, GetTogglesQueryVariables>(
    GetTogglesDocument,
    options
  );
}
export type GetTogglesQueryHookResult = ReturnType<typeof useGetTogglesQuery>;
export type GetTogglesLazyQueryHookResult = ReturnType<typeof useGetTogglesLazyQuery>;
export type GetTogglesQueryResult = Apollo.QueryResult<GetTogglesQuery, GetTogglesQueryVariables>;
export const GetScanErrorsDocument = gql`
  query GetScanErrors($scanStartZ: ID!, $nextToken: String) {
    scan(id: $scanStartZ) {
      id
      errors(nextToken: $nextToken) {
        items {
          id
          table
          scanStartZ
          ttl
          record
          errors {
            instancePath
            message
            params
          }
          schema
          matchedOldVersion {
            schema
          }
        }
        nextToken
      }
    }
  }
`;

/**
 * __useGetScanErrorsQuery__
 *
 * To run a query within a React component, call `useGetScanErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScanErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScanErrorsQuery({
 *   variables: {
 *      scanStartZ: // value for 'scanStartZ'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetScanErrorsQuery(
  baseOptions: Apollo.QueryHookOptions<GetScanErrorsQuery, GetScanErrorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScanErrorsQuery, GetScanErrorsQueryVariables>(
    GetScanErrorsDocument,
    options
  );
}
export function useGetScanErrorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScanErrorsQuery, GetScanErrorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScanErrorsQuery, GetScanErrorsQueryVariables>(
    GetScanErrorsDocument,
    options
  );
}
export type GetScanErrorsQueryHookResult = ReturnType<typeof useGetScanErrorsQuery>;
export type GetScanErrorsLazyQueryHookResult = ReturnType<typeof useGetScanErrorsLazyQuery>;
export type GetScanErrorsQueryResult = Apollo.QueryResult<
  GetScanErrorsQuery,
  GetScanErrorsQueryVariables
>;
export const GetScansDocument = gql`
  query GetScans {
    scans {
      id
      scanStartZ
      recordsScanned
      scanCompleteZ
      table
      ttl
    }
  }
`;

/**
 * __useGetScansQuery__
 *
 * To run a query within a React component, call `useGetScansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScansQuery(
  baseOptions?: Apollo.QueryHookOptions<GetScansQuery, GetScansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScansQuery, GetScansQueryVariables>(GetScansDocument, options);
}
export function useGetScansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetScansQuery, GetScansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScansQuery, GetScansQueryVariables>(GetScansDocument, options);
}
export type GetScansQueryHookResult = ReturnType<typeof useGetScansQuery>;
export type GetScansLazyQueryHookResult = ReturnType<typeof useGetScansLazyQuery>;
export type GetScansQueryResult = Apollo.QueryResult<GetScansQuery, GetScansQueryVariables>;
export const CollectionPostedDocument = gql`
  query CollectionPosted($id: ID!) {
    autopay(id: $id) {
      id
      status
      collectionFiles {
        fileNumber
        fileTotal
        fileEntries
        journalsTotal
      }
    }
  }
`;

/**
 * __useCollectionPostedQuery__
 *
 * To run a query within a React component, call `useCollectionPostedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionPostedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionPostedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCollectionPostedQuery(
  baseOptions: Apollo.QueryHookOptions<CollectionPostedQuery, CollectionPostedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CollectionPostedQuery, CollectionPostedQueryVariables>(
    CollectionPostedDocument,
    options
  );
}
export function useCollectionPostedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CollectionPostedQuery, CollectionPostedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CollectionPostedQuery, CollectionPostedQueryVariables>(
    CollectionPostedDocument,
    options
  );
}
export type CollectionPostedQueryHookResult = ReturnType<typeof useCollectionPostedQuery>;
export type CollectionPostedLazyQueryHookResult = ReturnType<typeof useCollectionPostedLazyQuery>;
export type CollectionPostedQueryResult = Apollo.QueryResult<
  CollectionPostedQuery,
  CollectionPostedQueryVariables
>;
export const CollectionReconciledDocument = gql`
  query CollectionReconciled($id: ID!, $nextToken: String) {
    autopay(id: $id) {
      id
      status
      listFees(nextToken: $nextToken) {
        items {
          id
          autopayId
          accountId
          accountName
          fileNumber
          paymentCount
          feeAmount
          transactionFee
        }
        nextToken
      }
    }
  }
`;

/**
 * __useCollectionReconciledQuery__
 *
 * To run a query within a React component, call `useCollectionReconciledQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionReconciledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionReconciledQuery({
 *   variables: {
 *      id: // value for 'id'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useCollectionReconciledQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectionReconciledQuery,
    CollectionReconciledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CollectionReconciledQuery, CollectionReconciledQueryVariables>(
    CollectionReconciledDocument,
    options
  );
}
export function useCollectionReconciledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectionReconciledQuery,
    CollectionReconciledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CollectionReconciledQuery, CollectionReconciledQueryVariables>(
    CollectionReconciledDocument,
    options
  );
}
export type CollectionReconciledQueryHookResult = ReturnType<typeof useCollectionReconciledQuery>;
export type CollectionReconciledLazyQueryHookResult = ReturnType<
  typeof useCollectionReconciledLazyQuery
>;
export type CollectionReconciledQueryResult = Apollo.QueryResult<
  CollectionReconciledQuery,
  CollectionReconciledQueryVariables
>;
export const SettlementPreparedDocument = gql`
  query SettlementPrepared($id: ID!, $nextToken: String) {
    autopay(id: $id) {
      id
      status
      listSettlementSummaries(nextToken: $nextToken) {
        nextToken
        items {
          id
          accountId
          accountName
          fileNumber
          paymentAmount
          feesTotal
          feePaymentTotal
          journalsTotal
        }
      }
    }
  }
`;

/**
 * __useSettlementPreparedQuery__
 *
 * To run a query within a React component, call `useSettlementPreparedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettlementPreparedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettlementPreparedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useSettlementPreparedQuery(
  baseOptions: Apollo.QueryHookOptions<SettlementPreparedQuery, SettlementPreparedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SettlementPreparedQuery, SettlementPreparedQueryVariables>(
    SettlementPreparedDocument,
    options
  );
}
export function useSettlementPreparedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettlementPreparedQuery,
    SettlementPreparedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SettlementPreparedQuery, SettlementPreparedQueryVariables>(
    SettlementPreparedDocument,
    options
  );
}
export type SettlementPreparedQueryHookResult = ReturnType<typeof useSettlementPreparedQuery>;
export type SettlementPreparedLazyQueryHookResult = ReturnType<
  typeof useSettlementPreparedLazyQuery
>;
export type SettlementPreparedQueryResult = Apollo.QueryResult<
  SettlementPreparedQuery,
  SettlementPreparedQueryVariables
>;
export const AutopayDocument = gql`
  query Autopay($id: ID!) {
    autopay(id: $id) {
      id
      collectionPosted
      status
      taskToken
      nsfCount
    }
  }
`;

/**
 * __useAutopayQuery__
 *
 * To run a query within a React component, call `useAutopayQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutopayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutopayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAutopayQuery(
  baseOptions: Apollo.QueryHookOptions<AutopayQuery, AutopayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutopayQuery, AutopayQueryVariables>(AutopayDocument, options);
}
export function useAutopayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AutopayQuery, AutopayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AutopayQuery, AutopayQueryVariables>(AutopayDocument, options);
}
export type AutopayQueryHookResult = ReturnType<typeof useAutopayQuery>;
export type AutopayLazyQueryHookResult = ReturnType<typeof useAutopayLazyQuery>;
export type AutopayQueryResult = Apollo.QueryResult<AutopayQuery, AutopayQueryVariables>;
export const AutopaysDocument = gql`
  query Autopays {
    listAutopays {
      items {
        id
        collectionPosted
        status
        taskToken
        version
      }
      nextToken
    }
  }
`;

/**
 * __useAutopaysQuery__
 *
 * To run a query within a React component, call `useAutopaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutopaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutopaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutopaysQuery(
  baseOptions?: Apollo.QueryHookOptions<AutopaysQuery, AutopaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutopaysQuery, AutopaysQueryVariables>(AutopaysDocument, options);
}
export function useAutopaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AutopaysQuery, AutopaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AutopaysQuery, AutopaysQueryVariables>(AutopaysDocument, options);
}
export type AutopaysQueryHookResult = ReturnType<typeof useAutopaysQuery>;
export type AutopaysLazyQueryHookResult = ReturnType<typeof useAutopaysLazyQuery>;
export type AutopaysQueryResult = Apollo.QueryResult<AutopaysQuery, AutopaysQueryVariables>;
export const CreateAutopayDocument = gql`
  mutation CreateAutopay($input: CreateAutopayInput!) {
    createAutopay(input: $input) {
      success
      error
      autopay {
        id
        collectionPosted
        taskToken
        status
      }
    }
  }
`;
export type CreateAutopayMutationFn = Apollo.MutationFunction<
  CreateAutopayMutation,
  CreateAutopayMutationVariables
>;

/**
 * __useCreateAutopayMutation__
 *
 * To run a mutation, you first call `useCreateAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutopayMutation, { data, loading, error }] = useCreateAutopayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAutopayMutation, CreateAutopayMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAutopayMutation, CreateAutopayMutationVariables>(
    CreateAutopayDocument,
    options
  );
}
export type CreateAutopayMutationHookResult = ReturnType<typeof useCreateAutopayMutation>;
export type CreateAutopayMutationResult = Apollo.MutationResult<CreateAutopayMutation>;
export type CreateAutopayMutationOptions = Apollo.BaseMutationOptions<
  CreateAutopayMutation,
  CreateAutopayMutationVariables
>;
export const ContinueAutopayDocument = gql`
  mutation ContinueAutopay($id: ID!) {
    continueAutopay(id: $id) {
      success
      error
    }
  }
`;
export type ContinueAutopayMutationFn = Apollo.MutationFunction<
  ContinueAutopayMutation,
  ContinueAutopayMutationVariables
>;

/**
 * __useContinueAutopayMutation__
 *
 * To run a mutation, you first call `useContinueAutopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContinueAutopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [continueAutopayMutation, { data, loading, error }] = useContinueAutopayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContinueAutopayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContinueAutopayMutation,
    ContinueAutopayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContinueAutopayMutation, ContinueAutopayMutationVariables>(
    ContinueAutopayDocument,
    options
  );
}
export type ContinueAutopayMutationHookResult = ReturnType<typeof useContinueAutopayMutation>;
export type ContinueAutopayMutationResult = Apollo.MutationResult<ContinueAutopayMutation>;
export type ContinueAutopayMutationOptions = Apollo.BaseMutationOptions<
  ContinueAutopayMutation,
  ContinueAutopayMutationVariables
>;
export const CreateSaaSFeesDocument = gql`
  mutation CreateSaaSFees($input: CreateSaaSFeesInput!) {
    createSaaSFees(input: $input) {
      success
      error
    }
  }
`;
export type CreateSaaSFeesMutationFn = Apollo.MutationFunction<
  CreateSaaSFeesMutation,
  CreateSaaSFeesMutationVariables
>;

/**
 * __useCreateSaaSFeesMutation__
 *
 * To run a mutation, you first call `useCreateSaaSFeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaaSFeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaaSFeesMutation, { data, loading, error }] = useCreateSaaSFeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSaaSFeesMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSaaSFeesMutation, CreateSaaSFeesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSaaSFeesMutation, CreateSaaSFeesMutationVariables>(
    CreateSaaSFeesDocument,
    options
  );
}
export type CreateSaaSFeesMutationHookResult = ReturnType<typeof useCreateSaaSFeesMutation>;
export type CreateSaaSFeesMutationResult = Apollo.MutationResult<CreateSaaSFeesMutation>;
export type CreateSaaSFeesMutationOptions = Apollo.BaseMutationOptions<
  CreateSaaSFeesMutation,
  CreateSaaSFeesMutationVariables
>;
import { z } from 'zod';

export const AWSDateSchema: z.ZodSchema<string> = z.string();
export const AWSDateTimeSchema: z.ZodSchema<string> = z.string();
export const AWSEmailSchema: z.ZodSchema<string> = z.string().email();
export const AWSIPAddressSchema: z.ZodSchema<string> = z.string();
export const AWSJSONSchema: z.ZodSchema<string> = z.string();
export const AWSPhoneSchema: z.ZodSchema<string> = z.string();
export const AWSTimeSchema: z.ZodSchema<string> = z.string();
export const AWSTimestampSchema: z.ZodSchema<string> = z.string();
export const AWSURLSchema: z.ZodSchema<string> = z.string();

export const EventSourceSchema = z.enum(['system']);
export const InvoiceCycleSchema = z.enum(['daily', 'monthly']);
export const ProductSchema = z.enum([
  'autopay_collections_eft',
  'autopay_collections_eft_returns',
  'autopay_payments_eft',
  'autopay_payments_eft_returns',
  'autopay_single_eft',
  'autopay_single_eft_returns',
  'tax',
  'unit',
]);
export const SettlementSummaryStatusSchema = z.enum([
  'COMPLETED',
  'FAILED',
  'PRESETTLED',
  'SETTLED',
  'STARTED',
]);
export const SortDirectionSchema = z.enum(['asc', 'desc']);
export const UnitsSortFieldSchema = z.enum([
  'amount',
  'buildingKey',
  'buildingName',
  'cleared',
  'description',
  'due',
  'outstanding',
  'ownerName',
  'payeeName',
  'posted',
  'propertyKey',
  'propertyName',
  'ref',
  'unitName',
]);

export const AddBusinessDaysInputSchema = z.lazy(() =>
  z.object({
    days: z.number().nullish(),
    location: z.string().nullish(),
    startDate: AWSDateSchema.nullish(),
  })
);

export const AddressSchema = z.lazy(() =>
  z.object({
    city: z.string().nullish(),
    country: z.string().nullish(),
    postal: z.string().nullish(),
    province: z.string().nullish(),
    street: z.string().nullish(),
    suite: z.string().nullish(),
  })
);

export const AutopaySchema = z.lazy(() =>
  z.object({
    collectionFiles: z.array(PaymentFileSchema).nullish(),
    collectionPosted: AWSDateSchema.nullish(),
    completedZ: AWSDateTimeSchema.nullish(),
    id: z.string(),
    listFees: AutopayFeeConnectionSchema.nullish(),
    listSettlementSummaries: SettlementSummaryConnectionSchema.nullish(),
    nsfCount: z.number().nullish(),
    status: z.string().nullish(),
    summary: SettlementSummarySchema.nullish(),
    taskToken: z.string().nullish(),
    version: z.string().nullish(),
  })
);

export const AutopayConnectionSchema = z.lazy(() =>
  z.object({
    items: z.array(AutopaySchema).nullish(),
    nextToken: z.string().nullish(),
  })
);

export const AutopayFeeSchema = z.lazy(() =>
  z.object({
    accountId: z.string(),
    accountName: z.string().nullish(),
    autopayId: z.string(),
    feeAmount: z.number().nullish(),
    fileNumber: z.string().nullish(),
    id: z.string(),
    paymentCount: z.number().nullish(),
    transactionFee: z.number().nullish(),
  })
);

export const AutopayFeeConnectionSchema = z.lazy(() =>
  z.object({
    items: z.array(AutopayFeeSchema).nullish(),
    nextToken: z.string().nullish(),
  })
);

export const AutopayPayloadSchema = z.lazy(() =>
  z.object({
    autopay: AutopaySchema.nullish(),
    error: z.string().nullish(),
    success: z.boolean().nullish(),
  })
);

export const AutopaySummaryInputSchema = z.lazy(() =>
  z.object({
    autopayId: z.string(),
    settlementId: z.string(),
  })
);

export const BankSchema = z.lazy(() =>
  z.object({
    accountNumber: z.string(),
    id: z.string(),
    institutionNumber: z.string(),
    name: z.string(),
    transitNumber: z.string(),
  })
);

export const BankInputSchema = z.lazy(() =>
  z.object({
    accountNumber: z.string(),
    institutionNumber: z.string(),
    name: z.string(),
    transitNumber: z.string(),
  })
);

export const BankPayloadSchema = z.lazy(() =>
  z.object({
    bank: BankSchema.nullish(),
    error: z.string().nullish(),
    success: z.boolean(),
  })
);

export const BillingAccountSchema = z.lazy(() =>
  z.object({
    address: AddressSchema.nullish(),
    domainId: z.string(),
    email: z.string().nullish(),
    id: z.string(),
    name: z.string(),
  })
);

export const BillingDetailRecordSchema = z.lazy(() =>
  z.object({
    amount: z.number(),
    billingAccountId: z.string().nullish(),
    cycle: InvoiceCycleSchema,
    dateKey: z.string(),
    description: z.string(),
    domain: z.string(),
    domainId: z.string(),
    id: z.string(),
    invoiceId: z.string(),
    product: ProductSchema,
    quantity: z.number(),
    rates: z.array(RatedBandSchema),
    skuId: z.string(),
    skuName: z.string(),
    usageId: z.string(),
    value: z.number(),
  })
);

export const ChargeSchema = z.lazy(() =>
  z.object({
    amount: z.number(),
    chargedZ: AWSDateTimeSchema,
    description: z.string(),
    id: z.string(),
    status: z.string(),
    transactionId: z.string(),
  })
);

export const CountrySchema = z.lazy(() =>
  z.object({
    id: z.string(),
    name: z.string(),
    postalLabel: z.string(),
    provinceLabel: z.string(),
    provinces: z.array(ProvinceSchema),
    timezone: z.string(),
  })
);

export const CreateAutopayInputSchema = z.lazy(() =>
  z.object({
    clearablesPosted: AWSDateSchema.nullish(),
    collectionPosted: AWSDateSchema,
  })
);

export const CreateSaaSFeesInputSchema = z.lazy(() =>
  z.object({
    fees: z.array(SaaSFeeInputSchema),
  })
);

export const HolidaySchema = z.lazy(() =>
  z.object({
    description: z.string().nullish(),
    id: z.string(),
    location: z.string().nullish(),
    name: z.string(),
    source: z.string().nullish(),
  })
);

export const InvoiceSchema = z.lazy(() =>
  z.object({
    amount: z.number(),
    billingAccount: BillingAccountSchema,
    billingAccountId: z.string().nullish(),
    billingBankId: z.string().nullish(),
    billingName: z.string().nullish(),
    charges: z.array(ChargeSchema).nullish(),
    cycle: InvoiceCycleSchema,
    domain: z.string(),
    domainId: z.string(),
    due: AWSDateSchema.nullish(),
    executionId: z.string().nullish(),
    id: z.string(),
    invoiced: AWSDateSchema,
    lines: z.array(InvoiceLineSchema).nullish(),
    outstanding: z.number().nullish(),
    period: z.string(),
    startedZ: AWSDateTimeSchema,
    status: z.string(),
    statusHistory: z.array(StatusHistorySchema),
    taxes: z.array(TaxLineSchema).nullish(),
  })
);

export const InvoiceLineSchema = z.lazy(() =>
  z.object({
    amount: z.number(),
    description: z.string(),
    driver: z.string(),
    id: z.string(),
    product: ProductSchema,
    quantity: z.number(),
    skuId: z.string(),
    skuName: z.string(),
    value: z.number(),
  })
);

export const ListAutopaySummariesFilterSchema = z.lazy(() =>
  z.object({
    accountId: z.string().nullish(),
  })
);

export const MutationSchema = z.lazy(() =>
  z.object({
    continueAutopay: SuccessPayloadSchema,
    createAutopay: AutopayPayloadSchema,
    createSaaSFees: SuccessPayloadSchema,
    registerBank: BankPayloadSchema,
    systemEvent: SystemEventSchema.nullish(),
  })
);

export const OldVersionMatchSchema = z.lazy(() =>
  z.object({
    errors: z.array(ScannerErrorSchema.nullish()).nullish(),
    schema: z.string().nullish(),
  })
);

export const PaymentFileSchema = z.lazy(() =>
  z.object({
    fileEntries: z.number(),
    fileNumber: z.string(),
    fileTotal: z.number(),
    journalsTotal: z.number().nullish(),
  })
);

export const ProvinceSchema = z.lazy(() =>
  z.object({
    code: z.string(),
    id: z.string(),
    name: z.string(),
    timezone: z.string().nullish(),
  })
);

export const QuerySchema = z.lazy(() =>
  z.object({
    addBusinessDays: AWSDateSchema.nullish(),
    autopay: AutopaySchema.nullish(),
    autopaySummary: SettlementSummarySchema.nullish(),
    countries: z.array(CountrySchema).nullish(),
    holidays: z.array(HolidaySchema).nullish(),
    invoice: InvoiceSchema.nullish(),
    listAutopaySummaries: SettlementSummaryConnectionSchema.nullish(),
    listAutopays: AutopayConnectionSchema.nullish(),
    pageUnits: UnitsPageSchema.nullish(),
    scan: ScanSchema.nullish(),
    scans: z.array(ScanSchema.nullish()).nullish(),
    toggle: TogglePayloadSchema,
    toggles: z.array(ToggleSchema.nullish()).nullish(),
  })
);

export const RatedBandSchema = z.lazy(() =>
  z.object({
    amount: z.number(),
    id: z.string(),
    portion: z.number(),
  })
);

export const RegisterBankInputSchema = z.lazy(() =>
  z.object({
    accountId: z.string(),
    bank: BankInputSchema,
    clientAccountId: z.string(),
  })
);

export const RelayPageInfoSchema = z.lazy(() =>
  z.object({
    endCursor: z.string().nullish(),
    hasNextPage: z.boolean(),
  })
);

export const SaaSFeeInputSchema = z.lazy(() =>
  z.object({
    accountId: z.string(),
    autopayId: z.string(),
    feeAmount: z.number(),
  })
);

export const ScanSchema = z.lazy(() =>
  z.object({
    errors: ScannerResultConnectionSchema.nullish(),
    id: z.string(),
    recordsScanned: z.number().nullish(),
    scanCompleteZ: AWSDateTimeSchema.nullish(),
    scanStartZ: AWSDateTimeSchema,
    table: z.string().nullish(),
    ttl: z.number().nullish(),
  })
);

export const ScannerErrorSchema = z.lazy(() =>
  z.object({
    instancePath: z.string().nullish(),
    keyword: z.string().nullish(),
    message: z.string().nullish(),
    params: AWSJSONSchema.nullish(),
    schemaPath: z.string().nullish(),
  })
);

export const ScannerResultSchema = z.lazy(() =>
  z.object({
    errors: z.array(ScannerErrorSchema.nullish()).nullish(),
    id: z.string(),
    matchedOldVersion: OldVersionMatchSchema.nullish(),
    record: AWSJSONSchema.nullish(),
    scanStartZ: AWSDateTimeSchema,
    schema: z.string().nullish(),
    table: z.string().nullish(),
    ttl: z.number().nullish(),
  })
);

export const ScannerResultConnectionSchema = z.lazy(() =>
  z.object({
    items: z.array(ScannerResultSchema.nullish()).nullish(),
    nextToken: z.string().nullish(),
  })
);

export const SettlementSummarySchema = z.lazy(() =>
  z.object({
    accountId: z.string().nullish(),
    accountName: z.string().nullish(),
    autopayId: z.string(),
    bankId: z.string().nullish(),
    billingName: z.string().nullish(),
    collectionPosted: AWSDateSchema,
    feePaymentTotal: z.number().nullish(),
    feesTotal: z.number().nullish(),
    fileNumber: z.string().nullish(),
    gstAmount: z.number().nullish(),
    id: z.string(),
    items: z.number().nullish(),
    journalsTotal: z.number().nullish(),
    key: z.string().nullish(),
    paymentAmount: z.number().nullish(),
    paymentCount: z.number().nullish(),
    returnedItems: z.number().nullish(),
    returnedTotal: z.number().nullish(),
    settledZ: AWSDateTimeSchema.nullish(),
    status: SettlementSummaryStatusSchema.nullish(),
    total: z.number().nullish(),
    transactionFeesAmount: z.number().nullish(),
  })
);

export const SettlementSummaryConnectionSchema = z.lazy(() =>
  z.object({
    items: z.array(SettlementSummarySchema).nullish(),
    nextToken: z.string().nullish(),
  })
);

export const StatusHistorySchema = z.lazy(() =>
  z.object({
    status: z.string(),
    z: AWSDateTimeSchema,
  })
);

export const SubscriptionSchema = z.lazy(() =>
  z.object({
    onSystemEvent: SystemEventSchema.nullish(),
  })
);

export const SuccessPayloadSchema = z.lazy(() =>
  z.object({
    error: z.string().nullish(),
    success: z.boolean().nullish(),
  })
);

export const SystemEventSchema = z.lazy(() =>
  z.object({
    detail: AWSJSONSchema.nullish(),
    detailType: z.string().nullish(),
    id: z.string().nullish(),
    source: EventSourceSchema.nullish(),
    time: z.string().nullish(),
  })
);

export const SystemEventInputSchema = z.lazy(() =>
  z.object({
    event: AWSJSONSchema,
  })
);

export const TaxLineSchema = z.lazy(() =>
  z.object({
    amount: z.number(),
    description: z.string(),
    id: z.string(),
    product: ProductSchema,
    skuId: z.string(),
    skuName: z.string(),
  })
);

export const ToggleSchema = z.lazy(() =>
  z.object({
    accountId: z.string(),
    description: z.string().nullish(),
    enabled: z.boolean(),
    id: z.string(),
  })
);

export const ToggleInputSchema = z.lazy(() =>
  z.object({
    accountId: z.string().nullish(),
    id: z.string(),
  })
);

export const TogglePayloadSchema = z.lazy(() =>
  z.object({
    error: z.string().nullish(),
    success: z.boolean(),
    toggle: ToggleSchema.nullish(),
  })
);

export const UnitSchema = z.lazy(() =>
  z.object({
    accountId: z.string(),
    accountName: z.string(),
    additionalInformation: z.string().nullish(),
    bathrooms: z.number(),
    bedrooms: z.number(),
    buildingId: z.string().nullish(),
    buildingName: z.string().nullish(),
    id: z.string(),
    legalAddress: z.string().nullish(),
    name: z.string(),
    occupancy: z.string().nullish(),
    ownerId: z.string().nullish(),
    ownerName: z.string().nullish(),
    propertyId: z.string(),
    propertyKey: z.string(),
    propertyName: z.string(),
    sqft: z.number(),
    test: z.boolean().nullish(),
  })
);

export const UnitEdgeSchema = z.lazy(() =>
  z.object({
    cursor: z.string(),
    node: UnitSchema,
  })
);

export const UnitsFilterInputSchema = z.lazy(() =>
  z.object({
    accountId: z.string().nullish(),
    ownerId: z.string().nullish(),
    ownerIds: z.array(z.string()).nullish(),
    propertyId: z.string().nullish(),
    propertyIds: z.array(z.string()).nullish(),
    search: z.string().nullish(),
  })
);

export const UnitsPageSchema = z.lazy(() =>
  z.object({
    edges: z.array(UnitEdgeSchema),
    pageInfo: RelayPageInfoSchema,
    totalCount: z.number(),
  })
);

export const UnitsSortInputSchema = z.lazy(() =>
  z.object({
    direction: SortDirectionSchema,
    field: UnitsSortFieldSchema,
  })
);
